// Generated with util/create-component.js
import React from "react";

import { LoaderComponentProps } from "./LoaderComponent.types";
import LogoLight from "./assets/Logo_light.png";
import "./LoaderComponent.scss";

const LoaderComponent: React.FC<LoaderComponentProps> = ({
  fullscreen,
  showText,
  text = "Loading",
}) => (
  <div
    data-testid="LoaderComponent"
    className={[
      "loader-component--wrapper",
      fullscreen ? "loader-component--wrapper-fullscreen" : undefined,
    ].join(" ")}
  >
    <div className="loader-component--wrapper--image-wrapper">
      <img src={LogoLight} alt="Daume logo" />
      {showText && (
        <p className="loader-component--wrapper--image-wrapper-text">{text}</p>
      )}
    </div>
  </div>
);

export default LoaderComponent;
