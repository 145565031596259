// Generated with util/create-component.js
import React from "react";
import { InputComponentProps } from "./InputComponent.types";
import "./InputComponent.scss";

const InputComponent: React.FC<InputComponentProps> = ({
  disabled,
  required,
  placeholder,
  value,
  onChange,
  type,
  rows = 4,
}) => {
  const placeholderText: string = `${placeholder}${required ? " *" : ""}`;

  return (
    <div data-testid="InputComponent" className="input-component-wrapper">
      <div className="input-component">
        {type === "multiline" ? (
          <textarea
            rows={rows}
            required={required}
            value={value as string}
            disabled={disabled}
            onChange={(value) => onChange(value.target.value)}
            placeholder={placeholderText}
            className={`${
              disabled ? "input-component-disabled" : "input-component-enabled"
            } `}
          />
        ) : (
          <input
            className={
              disabled ? "input-component-disabled" : "input-component-enabled"
            }
            required={required}
            placeholder={placeholderText}
            type={type || "text"}
            onChange={(event) => onChange(event.target.value)}
            value={value as string}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default InputComponent;
