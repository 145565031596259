// Generated with util/create-component.js
import React, { useEffect, useState } from "react";

import {
  ElementListComponentProps,
  ElementListEntry,
  ListComponentProps,
} from "./ListComponent.types";

import "./ListComponent.scss";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderComponent from "../LoaderComponent/LoaderComponent";

export const ListComponent: React.FC<ListComponentProps> = ({
  title,
  list,
  onClick,
}) => (
  <div data-testid="ListComponent" className="list-component">
    {title && <div className="list-component-title">{title}</div>}
    <ul className="list-component-list">
      {list.map((entry) => (
        <li
          key={`entry-${entry.id}`}
          className="list-component-entry"
          onClick={() => onClick(entry.id)}
        >
          <div className="list-component-entry-label">{entry.label}</div>
          <div className="list-component-entry-value">{entry.value}</div>
        </li>
      ))}
    </ul>
  </div>
);

export const ElementListComponent: React.FC<ElementListComponentProps> = ({
  title,
  list,
  onClick,
}) => {
  const maxInfinityScrollNumber: number = 50;
  const [currentItemCount, setCurrentItemCount] = useState<number>(0);
  const [usedListEntry, setUsedListEntry] = useState<ElementListEntry[]>([]);

  /**
   * this useEffect fills initially the used list for soem entries
   */
  useEffect(() => {
    addNewItemsToList();
  }, [list]);

  /**
   * Helper to add dynamically new data
   */
  const addNewItemsToList = (): void => {
    let addingItemsList: ElementListEntry[] = [];
    const newEndOfList: number =
      currentItemCount + maxInfinityScrollNumber > list.length
        ? list.length
        : currentItemCount + maxInfinityScrollNumber;

    addingItemsList = list.slice(currentItemCount, newEndOfList);
    setUsedListEntry([...usedListEntry, ...addingItemsList]);
    setCurrentItemCount(newEndOfList);
  };

  return (
    <div
      data-testid="ListComponent"
      className="element-list-component-wrapper"
      id="element-list-component"
    >
      <div className="element-list-component">
        {title && <div className="element-list-component-title">{title}</div>}
        <InfiniteScroll
          className="element-list-component-list"
          loader={
            currentItemCount !== list.length ? <LoaderComponent /> : <></>
          }
          dataLength={usedListEntry.length}
          hasMore={currentItemCount !== list.length}
          next={() => addNewItemsToList()}
          scrollableTarget="element-list-component"
        >
          {usedListEntry.map((entry, entryIndex) => (
            <li
              key={`entry-element-${entryIndex}`}
              className="element-list-component-entry"
              onClick={() => onClick(entry.id)}
            >
              <div className="element-list-component-entry-wrapper">
                <div className="element-list-component-entry-image">
                  {entry.image}
                </div>
                <div className="element-list-component-entry-text-wrapper">
                  <div className="element-list-component-entry-label">
                    {entry.label}
                  </div>
                  <div className="element-list-component-entry-value">
                    {entry.value}
                  </div>
                </div>
                <ArrowIcon className="element-list-component-entry-arrow" />
              </div>
            </li>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
