// Generated with util/create-component.js
import React, { useEffect, useState } from "react";
import LogoLight from "../IdleComponent/assets/Logo_light.png";
import IdleComponent from "../IdleComponent/IdleComponent";
import "./LayoutComponent.scss";
import { LayoutComponentProps } from "./LayoutComponent.types";

const LayoutComponent: React.FC<LayoutComponentProps> = ({
  idle,
  children,
}) => {
  const [innerHeight, setInnerHeight] = useState<number>(0);

  /**
   * Helper to listen to resize of browser window
   */
  useEffect(() => {
    const resizeWindow = () => setInnerHeight(window.innerHeight);
    // if window is undefined stop
    if (!window) return;
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <div
      data-testid="LayoutComponent"
      className="layout-component"
      style={{ height: `${innerHeight}px` }}
    >
      {idle ? (
        <IdleComponent />
      ) : (
        <div className={"layout-component-header"}>
          <img src={LogoLight} alt="Daume Group Logo" />
        </div>
      )}
      <div className="layout-component-content">{children}</div>
    </div>
  );
};

export default LayoutComponent;
