// Generated with util/create-component.js
import React from "react";

import { CheckboxComponentProps } from "./CheckboxComponent.types";

import "./CheckboxComponent.scss";

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  checked,
  onCheck,
  value,
  disabled,
}) => {
  const randomId = (Math.random() * Number(Date.now().toFixed(0))).toFixed(0);

  return (
    <div data-testid="CheckboxComponent" className="checkbox-component">
      <input
        disabled={disabled}
        checked={checked}
        type="checkbox"
        id={`checkbox-component-${randomId}`}
        className={[
          "checkbox-component-checkbox",
          checked ? "checkbox-component-checkbox-checked" : undefined,
        ].join(" ")}
        onChange={(event) => onCheck(event.target.checked)}
      />
      <label
        className={disabled ? "checkbox-component-label-disabled" : undefined}
        htmlFor={`checkbox-component-${randomId}`}
      >
        {value}
      </label>
    </div>
  );
};

export default CheckboxComponent;
