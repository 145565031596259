// Generated with util/create-component.js
import React from "react";

import { PopupComponentProps } from "./PopupComponent.types";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import "./PopupComponent.scss";

const PopupComponent: React.FC<PopupComponentProps> = ({
  children,
  closeFunction,
  title,
  icon,
  showClose,
  iconOnClick,
}) => (
  <div data-testid="PopupComponent" className="popup-component-wrapper">
    <div className="popup-component">
      <div className={"popup-component-header"}>
        {icon && (
          <div className={"popup-component-header-icon"} onClick={iconOnClick}>
            {icon}
          </div>
        )}
        {title && <div className={"popup-component-header-title"}>{title}</div>}
        {showClose && (
          <div
            className={"popup-component-header-icon-close"}
            onClick={closeFunction}
          >
            <CloseIcon />
          </div>
        )}
      </div>
      <div className={"popup-component-content"}>{children}</div>
    </div>
  </div>
);

export default PopupComponent;
