// Generated with util/create-component.js
import React from "react";

import { ButtonComponentProps } from "./ButtonComponent.types";

import "./ButtonComponent.scss";

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  onClick,
  title,
  isLoading,
  disabled,
  type,
}) => {
  const randomId = (Math.random() * Number(Date.now().toFixed(0))).toFixed(0);

  return (
    <div data-testid="ButtonComponent">
      <button
        className="button-component"
        disabled={disabled || isLoading}
        type={type || "submit"}
        onClick={() => (onClick ? onClick() : {})}
      >
        {isLoading ? (
          <div className="button-component-loading">
            <div className="button-component-loading-spinner">
              <div className="button-component-loading-mask">
                <div className="button-component-loading-circle" />
              </div>
            </div>
          </div>
        ) : (
          title
        )}
      </button>
    </div>
  );
};

export default ButtonComponent;
