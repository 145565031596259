// Generated with util/create-component.js
import React from "react";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { FabComponentProps } from "./FabComponent.types";
import { ReactComponent as PlusIcon } from "./assets/plus-icon.svg";

import "./FabComponent.scss";

const FabComponent: React.FC<FabComponentProps> = ({ actions }) => (
  <Fab
    icon={<PlusIcon className="fab-component--icon" />}
    event={"click"}
    alwaysShowTitle={true}
    mainButtonStyles={{ background: "#0f1920" }}
  >
    {actions.map((action, index) => (
      <Action
        text={action.text}
        onClick={action.onClick}
        style={{ backgroundColor: action.backgroundColor || "#0f1920" }}
        key={`fab-component-action-${index}`}
      >
        <div className="fab-component--icon-wrapper">{action.icon}</div>
      </Action>
    ))}
  </Fab>
);

export default FabComponent;
