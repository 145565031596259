// Generated with util/create-component.js
import React from "react";
import Logo from "./assets/Logo_light.png";

import { IdleComponentProps } from "./IdleComponent.types";

import "./IdleComponent.scss";

const IdleComponent: React.FC<IdleComponentProps> = ({ fullScreen }) => {
  return (
    <div
      data-testid="IdleComponent"
      className={fullScreen ? "idle-component-full-screen" : "idle-component"}
    >
      <img className="idle-component-image-wrapper" src={Logo} />
    </div>
  );
};

export default IdleComponent;
