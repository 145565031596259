// Generated with util/create-component.js
import React from "react";
import QrReader from "react-qr-reader";
import { ScannerComponentProps } from "./ScannerComponent.types";

import "./ScannerComponent.scss";

const ScannerComponent: React.FC<ScannerComponentProps> = ({
  onError,
  onScan,
  className,
  disabled,
}) => (
  <div data-testid="ScannerComponent" className="scanner-component--wrapper">
    {disabled || (
      <QrReader
        className={className}
        delay={150}
        onError={onError}
        onScan={(scan) => {
          if (scan) onScan(scan);
        }}
        style={{ width: "100%" }}
      />
    )}
  </div>
);

export default ScannerComponent;
